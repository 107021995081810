<template>
  <div>
  <div v-if="initCompleted && Object.keys(apartment).length === 0">
    <v-container>
      <v-row>
        <v-col class="mt-10">
          <h1>{{ $t('Apartment not found') }}</h1>
          <br/>
          <br/>
          <router-link :to="{ name: 'AdsCreate'}">{{ $t('Create new apartment') }}</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else-if="initCompleted && apartment.isWizzardCompleted === false">
    <v-container>
      <v-row>
        <v-col class="mt-10">
          <h1>{{ $t('Calendar Empty Listing Title') }}</h1>
          <br/>
          <br/>
          <router-link :to="{ name: 'AdForm', params: {
            id: this.$route.params.id,
            categoryConst: this.$route.params.categoryConst,
            stepSlug: apartment.status,
          }}">{{ $t('Calendar Empty Listing Description') }}</router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else-if="initCompleted">
    <div>
      <v-card
        flat
        class="d-flex flex-column justify-center"
        style="position: sticky; border-bottom: 1px solid lightgray !important; z-index: 5;"
        :style="$vuetify.breakpoint.mdAndDown ? 'top: 56px !important;' : 'top: 64px !important; padding-right:370px;'"
      >
      <div
        class="d-flex w-100 justify-end px-3 px-sm-5 py-4 pb-3 align-start align-sm-center"
      >
        <div
          class="d-sm-flex justify-space-between align-center"
          style="width: 100%;"
        >
          <month-switcher
            v-if="isCalendarLoaded"
            :start="calendar.view.activeStart"
            :end="calendar.view.activeEnd"
            :range-start="monthSwitcherStart"
            :title="monthName"
            :value="currentMonthNameDate"
            :valid-range-end="validRangeEnd"
            @updateCalendarStartDate="updateCalendarStartDate"
            @scrollToMonth="scrollToMonth"
          />
          <div class="mt-2 mt-sm-0">
            <filter-ads
              v-if="isCalendarLoaded"
              @update="switchAdById"
              :key="'filter_calendar'"
              :offsetPositionLeft="true"
              :isMultiSelect="false"
              :showCancel="true"
              :value="Number($route.params.id)"
              :status-filter="['pending', 'pending_fix', 'listed', 'unlisted']"
              :category-filter="1"
            />
          </div>
        </div>
        <div class="d-flex align-center">
          <rules-manager-modal
            :show="showRulesManager"
            :id="id"
            :calendarRules="calendarRules"
            @rulesChanged="rulesChanged"
            @close="showRulesManager = false"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.prevent="showRulesManager = true"
                :min-width="0"
                v-bind="attrs"
                v-on=" $vuetify.breakpoint.mdAndDown && on"
                class="cursor-pointer text-capitalize rounded-xl mr-2"
                :class="{'px-2 py-2' : $vuetify.breakpoint.mdAndDown}"
                elevation="0"
                color="white"
              >
                <v-icon>
                  mdi-note-edit-outline
                </v-icon>
                <span
                  v-if="$vuetify.breakpoint.lgAndUp"
                  class="font-weight-bold text-decoration-underline"
                >
                  {{ $t('Rule manager') }}
                </span>
              </v-btn>
            </template>
            <span>{{ $t('Rule manager') }}</span>
          </v-tooltip>
          <v-menu
            attach
            bottom
            left
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :class="{'px-2 py-2' : $vuetify.breakpoint.mdAndDown}"
                :min-width="0"
                v-bind="attrs"
                v-on="on"
                class="cursor-pointer text-capitalize rounded-xl"
                elevation="0"
                color="black"
                icon
              >
                <v-icon>
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link dense
                :to="{ name: 'AdForm', params: {
                  id: this.$route.params.id,
                  categoryConst: this.$route.params.categoryConst,
                  stepSlug: 'pricing',
                }}"
                class="font-weight-medium text-decoration-none"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Form Pricing Price Title') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link dense
                :to="{ name: 'AdForm', params: {
                  id: this.$route.params.id,
                  categoryConst: this.$route.params.categoryConst,
                  stepSlug: 'reservation',
                }}"
                class="font-weight-medium text-decoration-none"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-calendar-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Form Reservation Title') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item link dense
                :to="{ name: 'AdForm', params: {
                  id: this.$route.params.id,
                  categoryConst: this.$route.params.categoryConst,
                  stepSlug: 'activity',
                }}"
                class="font-weight-medium text-decoration-none"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-timeline-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Activity Log') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="apartment && apartment.icals && apartment.icals.length"
                link dense
                @click.prevent="refreshIcal"
                class="font-weight-medium text-decoration-none"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-refresh</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('Sync Calendar') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    <div
      style="display: flex; justify-content: space-evenly; flex;"
      class="font-weight-medium text-body-2 day-name-short"
    >
      <div v-for="dayName in dayNames"
        :key="dayName"
        style="width: 100%"
        class="px-2 text-center text-truncate font-weight-light">
        {{ $t(dayName) }}
      </div>
    </div>
    </v-card>
      <div
        id="calendar"
        @mouseup="mouseupGlobal"
        :style="$vuetify.breakpoint.lgAndUp ? 'margin-right:370px;' : ''"
        :class="{ 'non-admin-styles': !isAdmin }"
      >
      </div>
    </div>
    <calendar-sidebar
      v-show="$vuetify.breakpoint.mdAndDown ? showMenu : true"
      :id="id"
      :start="start"
      :end="end"
      :events="events"
      :selectedEvent="selectedEvent"
      :specialPricing="specialPricing"
      :showMenu="this.showMenu"
      :calendarRules="calendarRules"
      :calendarRulesDates="calendarRulesDates"
      @refetchEvents="refetchEvents"
      @clearSelectedDates="clearSelectedDates"
      @closeMenu="closeMenu"
      @priceChanged="priceChanged"
      @updateStartEnd="updateStartEnd"
      @rulesChanged="rulesChanged"
      @loadReservationInSidebar="loadReservationInSidebar"
      @removeReservationFromSidebar="removeReservationFromSidebar"
    />
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarSidebar from '@/components/calendar/Sidebar.vue';
import CalendarMixin from '@/components/calendar/CalendarMixin';
import FilterAds from '@/components/Filters/FilterAds.vue';
import MonthSwitcher from '@/components/calendar/CalendarMonthSwitcher.vue';
import RulesManagerModal from '@/components/calendar/RulesManagerModal.vue';

import _ from 'lodash';
import moment from 'moment';
import MetaTitleMixin from '@/lib/MetaTitleMixin';
import {
  DATE_FORMAT,
  EVENT_TYPE_ICAL,
  EVENT_TYPE_INQUIRY,
  EVENT_TYPE_RESERVATION,
  EVENT_TYPE_MANUAL,
  EVENT_TYPE_MIN_STAY_BLOCKED,
  daysBetweenDates,
  startEndString,
} from '@/components/calendar/config';

export default {
  name: 'Calendar',
  mixins: [CalendarMixin, MetaTitleMixin],
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: {
    CalendarSidebar,
    FilterAds,
    MonthSwitcher,
    RulesManagerModal,
  },
  data() {
    return {
      start: undefined,
      end: undefined,
      showMenu: false,
      showRulesManager: false,
      selectedEvent: undefined,
      hoverEnabled: false,
      selectingInProgress: false,
      events: [],
      specialPricing: {},
      availableRange: [],
      first: undefined,
      second: undefined,
      mouseIsDown: false,
      isCalendarLoaded: false,
      currentMonthNameDate: undefined,
      dayNames: [
        'Day Name Short.Monday',
        'Day Name Short.Tuesday',
        'Day Name Short.Wednesday',
        'Day Name Short.Thursday',
        'Day Name Short.Friday',
        'Day Name Short.Saturday',
        'Day Name Short.Sunday',
      ],
      calendarRules: [],
      calendarRulesDates: {},
      apartment: {},
      initCompleted: false,
      loadReservationID: undefined,
      preSelectEventId: undefined,
      preSelectEventStart: undefined,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'user']),
    validRangeStart() {
      return moment().startOf('month');
    },
    validRangeEnd() {
      return moment().add(36, 'months').endOf('month');
    },
    startEndString,
    monthName() {
      return moment(this.currentMonthNameDate)
        .locale(this.$i18n.locale)
        .format(this.$i18n.locale === 'sr'
          ? 'MMMM YYYY.'
          : 'MMMM YYYY');
    },
    monthSwitcherStart() {
      if (moment(this.calendar.view.activeStart) > moment().startOf('month')) {
        return moment().startOf('month').toString();
      }
      return this.calendar.view.activeStart;
    },
    allDatesFromEvents() {
      const dates = [];
      // Check if the start date is earlier than today's beginning.
      if (moment(this.start) < moment().startOf('day')) {
        // If it is, add the day before the current calendar view's active start date to the dates array.
        dates.push(moment(this.calendar.view.activeStart).subtract(1, 'days').startOf('day').format(DATE_FORMAT));
      } else {
        // Otherwise, simply add yesterday's date to the dates array.
        dates.push(moment().subtract(1, 'days').startOf('day').format(DATE_FORMAT));
      }
      // todo refactor to es6
      this.events.forEach((event) => {
        if (event.type !== EVENT_TYPE_MANUAL) {
          // substract 1 day from end - because we need only visual (one day less)
          const eventDates = daysBetweenDates(event.start, moment(event.end).clone().subtract(1, 'days').startOf('day'));
          for (let i = 0; i < eventDates.length; i += 1) {
            dates.push(eventDates[i]);
          }
        }
      });
      // end of the calendar range period
      dates.push(moment(this.calendar.view.activeEnd).add(1, 'days').startOf('day').format(DATE_FORMAT));
      // sort dates
      return dates.sort((a, b) => moment(a).diff(moment(b)));
    },
  },
  watch: {
    '$i18n.locale': function (locale) {
      this.calendar.setOption('locale', locale === 'sr' ? 'sr-ME' : 'en');
    },
  },
  mounted() {
    this.$store.dispatch('loading', true);
    this.$store
      .dispatch('calendarInitData', this.id)
      .then((data) => {
        this.initCompleted = true;
        this.apartment = data.apartment;
        // If apartment does not have all informations
        if (!data.apartment.isWizzardCompleted) {
          this.$store.dispatch('loading', false);
          return;
        }
        if (
          (this.user && data.apartment)
          && data.apartment.owner_id !== this.user.id
          && !this.isAdmin
        ) {
          this.$store.dispatch('loading', false);
          this.$router.push({ name: 'Dashboard' });
          return;
        }
        this.specialPricing = data.specialPricing;
        this.calendarRules = data.rules.rules;
        this.calendarRulesDates = data.rules.dates;

        // wait for DOM to load
        (async () => {
          await this.$nextTick(() => {
            // if route has a pre-select event parameters, init calendar from the start of the event
            this.preSelectEventId = this.$route.params.focusEventId;
            this.preSelectEventStart = this.$route.params.focusEventStart;

            if (this.preSelectEventStart) {
              this.initCalendar(this.preSelectEventStart);
              this.preSelectEventStart = undefined;
            } else {
              this.initCalendar();
            }
          });
        })();
      })
      .catch((error) => {
        this.initCompleted = true;
        this.$store.dispatch('setErrors', error.response.data.errors);
        this.$store.dispatch('loading', false);
      });

    window.addEventListener('resize', this.handleWindowResize);
  },
  destroyed() {
    if (this.calendar) {
      this.calendar.destroy();
    }
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.handleWindowResize);
    document.removeEventListener('mouseleave', this.mouseupGlobal);
  },
  methods: {
    updateStartEnd(range) {
      this.start = moment(range.start).clone().format(DATE_FORMAT);
      this.end = moment(range.end).clone().add(1, 'days').format(DATE_FORMAT);
      // this.calendar.select(this.start, this.end);
      const scrollTarget = document.querySelector(`td[data-date="${moment(this.end).format(DATE_FORMAT)}"]`);
      if (!scrollTarget) {
        this.updateCalendarStartDate(this.start);
      } else {
        this.scrollToMonth(this.start);
      }
      this.calendar.select(this.start, this.end);
    },
    switchAdById(apaId) {
      this.$router.push({ name: this.$route.name, params: { id: String(apaId), categoryConst: this.$route.params.categoryConst } });
      this.calendar.destroy();
      this.$router.go();
    },
    handleWindowResize() {
      if (this.calendar) {
        if (window.innerWidth > this.$vuetify.breakpoint.thresholds.lg - 200) {
          this.calendar.setOption('aspectRatio', 2);
        } else {
          // default fullcalendar aspect ratio
          this.calendar.setOption('aspectRatio', 1.35);
        }
      }
    },
    getEventByIdAndType(id, type) {
      return this.events.find((e) => e.type === type && e.id === Number(id));
    },
    loadEvent(eventId, eventType, scrollIntoView) {
      this.selectedEvent = this.getEventByIdAndType(eventId, eventType);
      if (this.selectedEvent) {
        this.calendar.select(
          this.selectedEvent.start,
          this.selectedEvent.end,
        );

        if (scrollIntoView) {
          const eventElement = document.querySelector(`[data-event-id="${this.$route.params.focusEventId}"]`);
          if (eventElement) {
            eventElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }
      }
    },
    clearSelectedDates() {
      this.start = undefined;
      this.end = undefined;
      this.calendar.unselect();
      this.availableRange = [];
      if (this.selectedEvent) {
        this.selectedEvent = undefined;
      }
    },
    handleMouseDown(elem) {
      // forbid click in past if event is not Inquiry or Reservation
      if (moment(elem.dataset.date) < moment().startOf('day') && ![EVENT_TYPE_INQUIRY, EVENT_TYPE_RESERVATION].includes(elem.dataset.eventType)) {
        if (!this.isAdmin) {
          return;
        }
      }
      if (elem.classList.contains('has-event')) {
        // Open Reservation
        if ([EVENT_TYPE_INQUIRY, EVENT_TYPE_RESERVATION].includes(elem.dataset.eventType)) {
          this.loadEvent(elem.dataset.eventId, elem.dataset.eventType);
          return;
        }
        // Prevent Click on ICAL
        if (elem.dataset.eventType === EVENT_TYPE_ICAL) {
          return;
        }
        this.selectedEvent = undefined;
      } else {
        // Forbid click on past dates
        if (moment(elem.dataset.date) < moment().startOf('day')) {
          if (!this.isAdmin) {
            return;
          }
        }
        // ako nema event  a datum u prosolsti je
        this.selectedEvent = undefined;
        this.calendar.unselect();
      }

      this.first = elem.dataset.date;
      this.second = elem.dataset.date;
      this.calendar.select(this.first, moment(this.first).add(1, 'day').format(DATE_FORMAT));

      if (elem.classList.contains('has-event') && elem.dataset.eventType === EVENT_TYPE_MIN_STAY_BLOCKED) {
        // get min and max of event and set as avaliable dates.
        const event = this.calendar.getEventById(elem.dataset.eventId);
        this.availableRange = daysBetweenDates(
          moment(event.start).clone().startOf('day'),
          moment(event.end).clone().subtract(1, 'days').startOf('day'),
        );
      } else {
        this.getSelectableDateRange(this.first);
      }
      // if mouse was held down for > 0.1 seconds - than trigger selecting...
      this.mouseIsDown = true;
      setTimeout(() => {
        if (this.mouseIsDown && this.$vuetify.breakpoint.lgAndUp) {
          this.selectingInProgress = true;
        }
      }, 30);
      setTimeout(() => {
        if (this.mouseIsDown && this.$vuetify.breakpoint.lgAndUp) {
          this.disableDatesOutsideAvailableRange();
        }
      }, 100);
    },
    handleMouseEnter(event, elem) {
      event.stopPropagation();
      if (this.selectingInProgress) {
        let { date } = elem.closest('td[data-date]').dataset;
        if (date) {
          if (!this.availableRange.includes(date)) {
            // should i get lowest or highest
            if (moment(date) < moment(this.availableRange[0])) {
              // eslint-disable-next-line
              date = this.availableRange[0];
            } else {
              date = this.availableRange[this.availableRange.length - 1];
            }
          }
          this.second = date;
          if (moment(this.first) > moment(this.second)) {
            this.calendar.select(
              this.second,
              moment(this.first).add(1, 'day').format(DATE_FORMAT),
            );
          } else {
            this.calendar.select(
              this.first,
              moment(this.second).add(1, 'day').format(DATE_FORMAT),
            );
          }
        }
      }
    },
    mouseupGlobal() {
      this.selectingInProgress = false;
      document.body.classList.remove('disable-date-cell-click');
      this.removeEnableDateCellClickClass();
      if (moment(this.first) > moment(this.second)) {
        this.start = this.second;
        this.end = moment(this.first).add(1, 'day').format(DATE_FORMAT);
      } else {
        this.start = this.first;
        this.end = moment(this.second).add(1, 'day').format(DATE_FORMAT);
      }
      this.mouseIsDown = false;
    },
    eventContent(arg) {
      // console.log(`${arg.event.extendedProps.type} = ${moment(arg.event.start).format(DATE_FORMAT)} - ${moment(arg.event.start).format(DATE_FORMAT)}`);
      // add class to each TD.
      const dates = daysBetweenDates(arg.event.start, arg.event.end);
      dates.pop(); // remove last element from array

      dates.forEach((date) => {
        const td = document.querySelector(`td[data-date="${date}"]`);
        if (td) {
          td.classList.add(`event-${arg.event.extendedProps.type}`);
          td.classList.add('has-event');
          td.dataset.eventType = arg.event.extendedProps.type;
          td.dataset.eventId = arg.event.id;

          if (arg.event.extendedProps.type === EVENT_TYPE_ICAL) {
            const eventWrapper = td.children[0].children[1];
            // ToDo test why we have IF
            if (!eventWrapper.querySelector('.ical-source-wrapper')) {
              const ical = document.createElement('div');
              ical.classList.add('ical-source-wrapper');
              ical.textContent = arg.event.extendedProps.source;
              eventWrapper.appendChild(ical);
            }
          }
          if (arg.event.extendedProps.type === EVENT_TYPE_MIN_STAY_BLOCKED) {
            const eventWrapper = td.children[0].children[1];
            // ToDo test why we have IF
            if (!eventWrapper.querySelector('.ical-source-wrapper')) {
              const ical = document.createElement('div');
              ical.classList.add('ical-source-wrapper');
              // ical.textContent = 'MIN STAY BLOCKED';
              ical.textContent = this.$t('Min stay blocked');
              eventWrapper.appendChild(ical);
            }
          }
        }
      });

      if (['inquiry', 'reservation'].includes(arg.event.extendedProps.type)) {
        const initials = (arg.event.extendedProps.guest.first_name.charAt(0) + arg.event.extendedProps.guest.last_name.charAt(0)).toUpperCase();

        return {
          html: `<div class="event-content">
                  <div class="event-avatar">
                    ${arg.event.extendedProps.userImg ? `<img src="${arg.event.extendedProps.userImg}" alt="${arg.event.extendedProps.guest.first_name}">` : `${initials}`}
                  </div>
                  ${this.getAdvancePaymentStatusIcon(arg.event.extendedProps)}
                  <div class="fc-event-title">${arg.event.title}</div>
                </div>`,
        };
      }
      return true;
    },
    mapEvent(event) {
      let title = '';
      let userImg = '';
      let display = '';
      if (event.type === EVENT_TYPE_RESERVATION) {
        title = `${event.guest.first_name} ${event.guest.last_name} • € ${event.total}`;
        if (
          event.guest.media_avatar
          && Object.values(event.guest.media_avatar)
          && Object.values(event.guest.media_avatar)[0]
        ) {
          userImg = Object.values(event.guest.media_avatar)[0].original_url;
        }
      }
      if (event.type === EVENT_TYPE_INQUIRY) {
        title = `${event.guest.first_name} ${event.guest.last_name} • € ${event.price} • Trip requested`;
      }
      if (event.type === EVENT_TYPE_ICAL) {
        display = 'background';
      }
      if (event.type === EVENT_TYPE_MANUAL) {
        display = 'background';
      }
      if (event.type === EVENT_TYPE_MIN_STAY_BLOCKED) {
        display = 'background';
      }
      if (event.type === 'special_price') {
        display = 'none';
      }
      return {
        ...event,
        start: event.start,
        end: event.end,
        className: `type-${event.type} ${event.type === EVENT_TYPE_RESERVATION && event.paid === true ? 'reservation-paid' : ''}`,
        display,
        title,
        userImg,
        overlap: false,
      };
    },
    removeEnableDateCellClickClass() {
      // remove 'enable-date-cell-click' class
      document.querySelectorAll('td.enable-date-cell-click').forEach((element) => {
        element.classList.remove('enable-date-cell-click');
      });
    },
    disableDatesOutsideAvailableRange() {
      // add class 'disable-date-cell-click' - disable click on day cell.
      document.body.classList.add('disable-date-cell-click');
      this.removeEnableDateCellClickClass();
      // Add on each available day class 'enable-date-cell-click'
      this.availableRange.forEach((date) => {
        if (document.querySelector(`td[data-date="${date}"]`)) {
          document.querySelector(`td[data-date="${date}"]`).classList.add('enable-date-cell-click');
        }
      });
    },
    findClosestDates(targetDate) {
      let targetIndex = this.allDatesFromEvents.findIndex((date) => moment(date) >= moment(targetDate));
      // Handle the case when the target date is before today (for admin only)
      if ((moment(targetDate) < moment()) && this.isAdmin) {
        // dont take yesterday's date as the available select limit
        if (this.allDatesFromEvents[0] === moment().subtract(1, 'days').format(DATE_FORMAT)) {
          targetIndex = 1;
        }
        return [
          moment(targetDate).clone().startOf('month').format(DATE_FORMAT),
          this.allDatesFromEvents[targetIndex],
        ];
      }
      return [
        this.allDatesFromEvents[targetIndex - 1], // start
        this.allDatesFromEvents[targetIndex], // end
      ];
    },
    getSelectableDateRange(selectedDate) {
      const [start, end] = this.findClosestDates(selectedDate);
      this.availableRange = daysBetweenDates(
        moment(start).clone().add(1, 'days').startOf('day'), // add one day
        moment(end).clone().subtract(1, 'days').startOf('day'), // remove one day
      );
    },
    // dateClick(event) {
    //   if (!this.start && !this.end) {
    //     this.start = event.dateStr;
    //     this.calendar.select(this.start, moment(this.start).add(1, 'day').format(DATE_FORMAT));
    //     this.getSelectableDateRange(this.start);
    //     this.disableDatesOutsideAvailableRange();
    //   } else if (this.start && !this.end) {
    //     if (!this.availableRange.includes(event.dateStr)) {
    //       return;
    //     }
    //     this.end = moment(event.date).format(DATE_FORMAT);
    //     if (moment(this.start) > moment(this.end)) {
    //       const { start } = this;
    //       const { end } = this;
    //       this.start = end;
    //       this.end = start;
    //     }
    //     this.end = moment(this.end).add(1, 'day').format(DATE_FORMAT);
    //     this.calendar.select(this.start, this.end);
    //     document.body.classList.remove('disable-date-cell-click');
    //     this.removeEnableDateCellClickClass();
    //   } else if (this.start && this.end) {
    //     this.calendar.unselect();
    //     this.start = event.dateStr;
    //     this.end = undefined;
    //     this.calendar.select(this.start, moment(this.start).add(1, 'day').format(DATE_FORMAT));
    //     this.getSelectableDateRange(this.start);
    //     this.disableDatesOutsideAvailableRange();
    //   }
    // },
    getEvents(info, successCallback, failureCallback) {
      const params = {
        id: this.id,
        start: (this.initDate)
          ? moment(this.initDate).startOf('month').format(DATE_FORMAT)
          : moment().startOf('month').format(DATE_FORMAT),
      };
      this.$store.dispatch('loading', true);
      this.$store
        .dispatch('calendar', params)
        .then((data) => {
          this.events = data;
          this.removeAllMinStayBlockedFromCalendar();
          this.removeAllIcalFromCalendar();

          successCallback(
            data.map((event) => this.mapEvent(event)),
          );
          if (this.loadReservationID) {
            this.loadEvent(this.loadReservationID, EVENT_TYPE_RESERVATION);
            this.loadReservationID = undefined;
          }
          this.$store.dispatch('loading', false);

          // pre-select specified event in calendar from route params
          if (
            this.preSelectEventId
          ) {
            this.loadEvent(this.preSelectEventId, 'reservation', true);
            if (!this.showMenu && this.$vuetify.breakpoint.mdAndDown) {
              this.openMenu();
            }
            this.preSelectEventId = undefined;
          }
        })
        .catch((error) => {
          failureCallback(error);
          console.warn('ERROR');
          console.warn(error);
          // this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
    initCalendar(initDate = moment.now()) {
      const calendarEl = document.getElementById('calendar');

      this.calendar = new Calendar(calendarEl, {
        plugins: [interactionPlugin, dayGridPlugin, multiMonthPlugin],
        initialView: 'multiMonthYearCustom', // dayGridYear
        firstDay: 1,
        locale: this.$i18n.locale === 'sr'
          ? 'sr-ME'
          : '',
        contentHeight: 'auto',
        initialDate: initDate,
        unselectAuto: false,
        aspectRatio: window.innerWidth > this.$vuetify.breakpoint.thresholds.lg - 200 ? 2 : 1.35,
        windowResizeDelay: 200,
        views: {
          multiMonthYearCustom: {
            type: 'multiMonthYear',
            multiMonthMaxColumns: 1,
            duration: {
              months: Math.abs(moment(initDate).diff(this.validRangeEnd, 'months')) >= 12
                ? 12
                : Math.abs(moment(initDate).diff(this.validRangeEnd, 'months')),
            },
            multiMonthTitleFormat: { month: 'long', year: 'numeric' },
            validRange: {
              start: this.validRangeStart,
              end: this.validRangeEnd,
            },
          },
        },
        loading: (bool) => {
          if (bool === false) { // loading finished
            this.isCalendarLoaded = true;
          }
        },
        events: (info, successCallback, failureCallback) => this.getEvents(info, successCallback, failureCallback),
        eventContent: (arg) => this.eventContent(arg),
        dayCellDidMount: (cell) => {
          // ToDo remove event liseners on unmount
          cell.el.addEventListener('mousedown', () => this.handleMouseDown(cell.el));
          cell.el.addEventListener('mouseenter', (event) => this.handleMouseEnter(event, cell.el));

          const date = moment(cell.date).format(DATE_FORMAT);

          // DRAW PRICE PER DAY + SPECIAL PRICE
          const pricingWrapper = document.createElement('div');
          pricingWrapper.classList.add('price');
          const priceNumber = document.createElement('span');
          priceNumber.textContent = this.getPriceStringForDate(date);
          priceNumber.classList.add('price-value');
          pricingWrapper.appendChild(priceNumber);
          cell.el.querySelector('.fc-daygrid-day-events').appendChild(pricingWrapper);

          // DRAW PRICE ADJUSTMENT RULE
          this.drawPriceAdjustmentForDate(cell.el, date);

          // DRAW MIN STAY
          const minStayValueEl = document.createElement('span');
          minStayValueEl.classList.add('minStayLabel');
          minStayValueEl.textContent = this.getMinStayForDate(date);

          const minStayDotEl = document.createElement('span');
          minStayDotEl.style.background = this.getRuleColorForDate(date);
          minStayDotEl.classList.add('minStayDot');

          const minStayWrapperEl = document.createElement('div');
          minStayWrapperEl.classList.add('minStay');
          minStayWrapperEl.appendChild(minStayDotEl);
          minStayWrapperEl.appendChild(minStayValueEl);
          cell.el.querySelector('.fc-daygrid-day-top').appendChild(minStayWrapperEl);

          return cell;
        },
        dateClick: (event) => {
          if ((!this.showMenu && this.$vuetify.breakpoint.mdAndDown)) {
            if (this.isAdmin) {
              if (!event.dayEl.classList.contains(`event-${EVENT_TYPE_ICAL}`)) {
                setTimeout(() => {
                  this.openMenu();
                }, 50);
                return;
              }
            }
            if ((!(moment(event.date).startOf('day') < moment().startOf('day'))
              && !event.dayEl.classList.contains(`event-${EVENT_TYPE_ICAL}`))
              || (event.dayEl.classList.contains(`event-${EVENT_TYPE_RESERVATION}`)
              || event.dayEl.classList.contains(`event-${EVENT_TYPE_INQUIRY}`))) {
              setTimeout(() => {
                this.openMenu();
              }, 50);
            }
          }
        },
        eventClick: () => {
          if (!this.showMenu && this.$vuetify.breakpoint.mdAndDown) {
            setTimeout(() => {
              this.openMenu();
            }, 50);
          }
        },
        // eslint-disable-next-line
        selectOverlap: (event) => {
          return event.extendedProps.type === EVENT_TYPE_MANUAL;
        },
        selectAllow(info) {
          return (info.start >= new Date().setHours(0, 0, 0, 0));
        },
      });

      this.calendar.render();

      // Set SEO title
      this.$title = `${this.$t('Calendar SEO Title', { title: this.apartment.name })}`;

      if (this.hoverEnabled) {
        this.initHoverEffect();
      }

      // Set MONTH NAME of currently focused month in the view - ( month name header on scroll)
      // this.onScrollChangeCurrentMonthName();
      window.addEventListener('scroll', this.scrollHandler);

      // Clear selection when user drag mouse out of the window!
      document.addEventListener('mouseleave', (event) => {
        if (this.mouseIsDown && (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight))) {
          this.mouseupGlobal();
        }
      });

      this.markTodayAsCurrentDate();

      this.scrollToTopOfCalendar();

      this.setFirstMonthInViewAsMainMonthName();

      if (this.selectedEvent) {
        this.selectedEvent = undefined;
      }
    },
    scrollHandler: _.debounce(function () {
      // When the new month reaches 5/6 of the screen height, display it's name in header
      const monthHeaders = document.querySelectorAll('.fc-multimonth-title');

      let date;
      monthHeaders.forEach((header) => {
        const headerPosition = header.getBoundingClientRect();
        if (headerPosition.top <= (window.innerHeight - ((window.innerHeight / 6) * 5))) {
          date = header.parentElement.parentElement.dataset.date;
        }
      });
      if (!date) {
        const firstHeader = document.querySelector('.fc-multimonth-title');
        date = firstHeader.parentElement.parentElement.dataset.date;
      }

      // change only if month if different than current one
      if (this.currentMonthNameDate !== date && undefined !== date) {
        this.currentMonthNameDate = date;
      }
    }, 30),
    markTodayAsCurrentDate() {
      const todaySpan = document.createElement('span');
      todaySpan.classList.add('fc-day-today-label');
      todaySpan.textContent = this.$t('Today');
      if (!document.querySelector('.fc-day-today-label') && document.querySelector('.fc-day-today')) {
        document.querySelector('.fc-day-today').firstChild.children[0].firstChild.appendChild(todaySpan);
      }
    },
    scrollToTopOfCalendar() {
      document.querySelector(`td[data-date="${moment(this.calendar.view.activeStart).format(DATE_FORMAT)}"]`).scrollIntoView({ behavior: 'smooth', block: 'end' });
    },
    scrollToMonth(month) {
      const selector = `td[data-date="${moment(new Date(month)).format(DATE_FORMAT)}"]`;
      if (document.querySelector(selector)) {
        window.scrollTo({
          behavior: 'smooth',
          block: 'end',
          top:
            document.querySelector(selector).getBoundingClientRect().top
            - document.body.getBoundingClientRect().top
            - 226, // 236
        });
      }
    },
    updateCalendarStartDate(month) {
      this.calendar.destroy();
      const date = new Date(month);
      this.initDate = date;
      // ToDo we should use this.initDate, no need to pass it thro
      this.initCalendar(date);
    },
    setFirstMonthInViewAsMainMonthName() {
      const firstMonthHeader = document.querySelectorAll('.fc-multimonth-title')[0];
      this.currentMonthNameDate = firstMonthHeader.parentElement.parentElement.dataset.date;
    },
    removeAllMinStayBlockedFromCalendar() {
      this.calendar.getEvents()
        .filter((event) => event.extendedProps.type === EVENT_TYPE_MIN_STAY_BLOCKED)
        .forEach((event) => event.remove());

      document.querySelectorAll(`td.event-${EVENT_TYPE_MIN_STAY_BLOCKED}`).forEach((element) => {
        element.classList.remove('has-event');
        element.classList.remove(`event-${EVENT_TYPE_MIN_STAY_BLOCKED}`);
        element.removeAttribute('data-event-type');
        const label = element.querySelector('div.ical-source-wrapper');
        label.remove();
      });
    },
    removeAllIcalFromCalendar() {
      document.querySelectorAll(`td.event-${EVENT_TYPE_ICAL}`).forEach((element) => {
        element.classList.remove('has-event');
        element.classList.remove(`event-${EVENT_TYPE_ICAL}`);
        element.removeAttribute('data-event-type');
        const label = element.querySelector('div.ical-source-wrapper');
        label.remove();
      });
    },
    removeAllReservationsFromCalendar() {
      document.querySelectorAll(`td.event-${EVENT_TYPE_RESERVATION}`).forEach((element) => {
        element.classList.remove('has-event');
        element.classList.remove(`event-${EVENT_TYPE_RESERVATION}`);
        element.removeAttribute('data-event-type');
      });
    },
    loadReservationInSidebar(reservationId) {
      this.refetchEvents();
      this.clearSelectedDates();
      this.loadReservationID = reservationId;
    },
    removeReservationFromSidebar() {
      this.refetchEvents();
      this.clearSelectedDates();
    },
    refetchEvents() {
      // this.removeAllMinStayBlockedFromCalendar();
      // this.removeAllIcalFromCalendar();
      this.removeAllReservationsFromCalendar();
      this.calendar.refetchEvents();
    },
    // removeAllHoverDayClass() {
    //   document.querySelectorAll('td.hover-day').forEach((element) => {
    //     element.classList.remove('hover-day');
    //   });
    // },
    // initHoverEffect() {
    //   document.getElementsByClassName('fc-daygrid-day').forEach((element) => {
    //     element.addEventListener('mouseover', (e) => {
    //       if (this.start && !this.end) {
    //         const cell = e.target.closest('td');
    //         if (cell) {
    //           const potencionalEndDate = cell.dataset.date;
    //           if (potencionalEndDate) {
    //             // check if End is > Start and get Dates between
    //             const dates = (moment(this.start) > moment(potencionalEndDate))
    //               ? daysBetweenDates(potencionalEndDate, this.start)
    //               : daysBetweenDates(this.start, potencionalEndDate);
    //               // remove all class added before...
    //             this.removeAllHoverDayClass();
    //             // add class on each date between two dates
    //             dates.forEach((date) => {
    //               document.querySelector(`[data-date="${date}"]`).classList.add('hover-day');
    //             });
    //           }
    //         }
    //       }
    //     });
    //   });
    // },
    openMenu() {
      this.showMenu = true;
    },
    closeMenu() {
      this.showMenu = false;
    },
    getDatesBetweenCalendarStartAndEnd() {
      return daysBetweenDates(
        moment(this.calendar.view.activeStart).startOf('day').format(DATE_FORMAT),
        moment(this.calendar.view.activeEnd).subtract(1, 'days').startOf('day').format(DATE_FORMAT),
      );
    },
    updatePricing() {
      const dates = this.getDatesBetweenCalendarStartAndEnd();
      dates.forEach((date) => {
        const cell = document.querySelector(`td[data-date="${date}"]`);
        if (cell) {
          const el = cell.querySelector('.price-value');
          if (el) {
            // Update Price if it's not same value
            const priceString = this.getPriceStringForDate(date);
            if (el.innerHTML !== priceString) {
              el.innerHTML = priceString;
            }
          }
        }
      });
    },
    updateRules() {
      const dates = this.getDatesBetweenCalendarStartAndEnd();
      dates.forEach((date) => {
        const cell = document.querySelector(`td[data-date="${date}"]`);
        if (cell) {
          this.updatePriceAdjustment(cell, date);
          this.updateMinStay(cell, date);
        }
      });
    },
    updateMinStay(cell, date) {
      const minStay = this.getMinStayForDate(date);
      const minStayEl = cell.querySelector('.minStayLabel');
      const color = this.getRuleColorForDate(date);
      const colorEl = cell.querySelector('.minStayDot');
      if (Number(minStayEl.innerHTML) !== minStay || colorEl.style.backgroundColor !== this.convertHex(color)) {
        // update dot + label
        colorEl.style.background = color;
        minStayEl.innerHTML = minStay;
      }
      // }
    },
    updatePriceAdjustment(cell, date) {
      const el = cell.querySelector('.price-adjusted');
      const elMobile = cell.querySelector('.price-adjusted-mobile');
      const value = this.getPriceAdjustmentForDate(date);
      if (!value) {
        if (el) {
          // clear date
          el.remove();

          // clear date on mobile
          if (elMobile) {
            elMobile.remove();
          }
        }
      } else if (value) {
        if (el) {
          // if value not same - update
          const priceString = this.getPriceAdjustmentStringForDate(value);
          const color = this.getRuleColorForDate(date);
          if (el.innerHTML !== priceString || el.style.backgroundColor !== this.convertHex(color)) {
            el.innerHTML = priceString;
            el.style.color = color;

            // apply same for mobile
            elMobile.innerHTML = priceString;
            elMobile.style.color = color;
          }
        } else {
          // create
          this.drawPriceAdjustmentForDate(cell, date);
        }
      }
    },
    drawPriceAdjustmentForDate(el, date) {
      const priceAdjustment = this.getPriceAdjustmentForDate(date);
      if (priceAdjustment) {
        const adjustedPriceEl = document.createElement('span');

        adjustedPriceEl.style.color = this.getRuleColorForDate(date);
        adjustedPriceEl.textContent = this.getPriceAdjustmentStringForDate(priceAdjustment);
        adjustedPriceEl.classList.add('price-adjusted');
        el.querySelector('.price').appendChild(adjustedPriceEl);

        const adjustedPriceMobileEl = document.createElement('span');
        adjustedPriceMobileEl.textContent = adjustedPriceEl.textContent;
        adjustedPriceMobileEl.style.color = adjustedPriceEl.style.color;

        adjustedPriceMobileEl.classList.add('price-adjusted-mobile');
        el.querySelector('.fc-daygrid-day-top').appendChild(adjustedPriceMobileEl);
      }
    },
    convertHex(color) {
      const hexCharacters = color.replace('#', '');
      const r = parseInt(hexCharacters.substring(0, 2), 16);
      const g = parseInt(hexCharacters.substring(2, 4), 16);
      const b = parseInt(hexCharacters.substring(4, 6), 16);
      return `rgb(${r}, ${g}, ${b})`;
    },
    fetchMinStayBlockedAndUpdate() {
      // fetch min stay blocked
      const params = {
        id: this.id,
        start: (this.initDate)
          ? moment(this.initDate).startOf('month').format(DATE_FORMAT)
          : moment().startOf('month').format(DATE_FORMAT),
      };
      this.$store
        .dispatch('minStayBlocked', params)
        .then((data) => {
          // get all events except EVENT_TYPE_MIN_STAY_BLOCKED
          const eventsWithoutMinStayBlocked = this.events.filter((x) => x.type !== EVENT_TYPE_MIN_STAY_BLOCKED);

          // eventsWithoutMinStayBlocked + data (min stay blocked from api)
          this.events = [...eventsWithoutMinStayBlocked, ...data];

          if (data && data.length) {
            // remove old min stay blocked from calendar
            this.removeAllMinStayBlockedFromCalendar();

            // add new min stay blocked to calendar
            this.calendar.batchRendering(() => {
              data.forEach((event) => {
                this.calendar.addEvent(
                  this.mapEvent(event),
                );
              });
            });
          }
        })
        .catch((error) => {
          console.warn('ERROR');
          console.warn(error);
          this.$store.dispatch('loading', false);
        });
    },
    rulesChanged() {
      this.$store
        .dispatch('calendarRules', this.id)
        .then((data) => {
          this.calendarRules = data.rules;
          this.calendarRulesDates = data.dates;
          this.updateRules();
          this.fetchMinStayBlockedAndUpdate();
        })
        .catch((error) => {
          console.warn('ERROR');
          console.warn(error);
          this.$store.dispatch('loading', false);
        });
    },
    priceChanged() {
      this.$store.dispatch('loading', true);
      this.$store
        .dispatch('specialPricing', this.id)
        .then((data) => {
          this.specialPricing = data;
          this.updatePricing();
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', this.$t('Price updated for', { date: this.startEndString }));
        })
        .catch((error) => {
          console.warn('ERROR');
          console.warn(error);
          this.$store.dispatch('loading', false);
          this.$store.dispatch('message', error.response.data.message);
        });
    },
    refreshIcal() {
      this.$store.dispatch('loading', true);
      this.$store
        .dispatch('refreshIcal', this.id)
        .then(() => {
          this.refetchEvents();
          this.clearSelectedDates();
          this.$store.dispatch('message', this.$t('Calendar synchronized'));
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('message', error.response.data.message);
          this.$store.dispatch('loading', false);
        });
    },
  },
};
</script>
<style lang="scss" >
  @import '~vuetify/src/styles/settings/_variables';
  // EVENTS
  #calendar .fc-daygrid-day .fc-daygrid-bg-harness{
    pointer-events: none;
  }
  .fc-event.type-manual.fc-bg-event,
  .fc-event.type-min_stay_blocked.fc-bg-event,
  .fc-event.type-ical.fc-bg-event{
    background-image: radial-gradient(#acacac 2.5px, transparent 2.5px), radial-gradient(#acacac 2.5px, transparent 2.5px);
    background-size: 12px 12px;
    background-position: 0 0, 6px 6px;
    background-color: #ffffff;
  }
  // event type = 'ical'
  #calendar .ical-source-wrapper {
    position: relative;
    padding: 5px;
    margin-top: 8px;
    background: white;
    z-index: 999 !important;
    color: rgb(150, 150, 150);
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px !important;
    margin-left: 10px !important;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: fit-content;
    transition: all .2s;
    letter-spacing: 1px;
  }
  #calendar .event-ical .fc-daygrid-day-top .fc-daygrid-day-number {
    padding: 5px;
    background: white;
    color: black;
  }

  #calendar .fc-h-event.type-inquiry,
  #calendar .fc-h-event.type-reservation{
    overflow: hidden;
    padding:7px;
    border-color: black;
    border-width: 2px;
    background: white;
    & .fc-event-main {
      color: black;
      position: relative;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    & .event-content {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      & .event-avatar {
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        border-radius: 100%;
        min-width: 38px;
        min-height: 38px;
        max-width: 38px;
        max-height: 38px;
        background: lightgray;
        padding: 8px;
        font-size: 16px;
        overflow: hidden;

        & img {
          min-width: 38px;
          min-height: 38px;
          max-width: 38px;
          max-height: 38px;
          object-fit: cover;
        }
      }
    }
  }
  #calendar .fc-h-event.type-reservation{
    background: #da511a;
    border-color: #da511a;
    & .fc-event-main{
      color: white;
    }
    &.reservation-paid{
      background: #308130;
      border-color: #308130;
    }
  }
  #calendar .fc-h-event.type-inquiry{
    border-style: dashed;
  }
  #calendar .fc-daygrid-event.fc-event-start {
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    margin-left: 20px;
    border-right-width: 0;
  }
  #calendar .fc-daygrid-event.fc-event-end {
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-left-width: 0;
  }
  #calendar .has-event .fc-daygrid-event-harness {
    margin-top: 0;
  }
  #calendar .has-event .fc-daygrid-day .fc-daygrid-day-events {
    padding: 0;
  }
  #calendar .has-event .fc-event-start.fc-event-end{
    border-right-width: 2px;
    border-left-width: 2px;
  }
  #calendar .fc-daygrid-event .fc-event-title {
    font-weight: 500;
    font-size: 18px;
    text-overflow: ellipsis;
  }
  #calendar .fc-day .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    max-width: 100%;
    & .price-adjusted {
      font-size: 14px;
    }
    & .price-value{
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 959px) and (max-width: 1264px) {
    #calendar .fc-multimonth-daygrid-table {
      height: auto !important;
      min-height: auto !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day {
      height: 140px !important;
      max-height: 140px !important;
      min-height: 140px !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day .fc-daygrid-day-frame {
      height: 100% !important;
    }
  }
  @media (min-width: 650px) and (max-width: 959px) {
    #calendar .fc-multimonth-daygrid-table {
      height: auto !important;
      min-height: auto !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day {
      height: 100px !important;
      max-height: 100px !important;
      min-height: 100px !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day .fc-daygrid-day-frame {
      height: 100% !important;
    }
  }
  @media (min-width: 500px) and (max-width: 650px) {
    #calendar .fc-multimonth-daygrid-table {
      height: auto !important;
      min-height: auto !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day {
      height: 80px !important;
      max-height: 80px !important;
      min-height: 80px !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day .fc-daygrid-day-frame {
      height: 100% !important;
    }
  }
  @media (max-width: 500px) {
    #calendar .fc-multimonth-daygrid-table {
      height: auto !important;
      min-height: auto !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day {
      height: 60px !important;
      max-height: 60px !important;
      min-height: 60px !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day .fc-daygrid-day-frame {
      height: 100% !important;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    #calendar .fc-multimonth-daygrid-table {
      height: auto !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day {
      height: 140px !important;
      max-height: 140px !important;
      min-height: 140px !important;
    }
    #calendar .fc-multimonth-daygrid-table .fc-day .fc-daygrid-day-frame {
      height: 100% !important;
    }
    #calendar .fc-day .price {
      font-size: 18px;
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    #calendar .fc-daygrid .fc-day.has-event:not(.event-min_stay_blocked) .fc-daygrid-day-top{
      justify-content: center !important;
      & .minStay{
        display: none !important;
      }
    }
    #calendar .fc-daygrid .fc-day .price {
      display: flex;
      padding-top: 30px;
      position: relative;
      bottom: 7px;
    }
    #calendar .ical-source-wrapper {
      margin-top: 30px;
    }
    #calendar .has-event .fc-event.fc-daygrid-event .event-content .event-avatar{
      min-width: 28px;
      min-height: 28px;
      max-width: 28px;
      max-height: 28px;
      padding: 0px;
      font-size: 14px;

      & img {
        min-width: 28px;
        min-height: 28px;
        max-width: 28px;
        max-height: 28px;
      }
    }
    #calendar .has-event .fc-event.fc-daygrid-event {
      margin-top: 10px ;
    }
    #calendar .has-event .fc-daygrid-event-harness {
      visibility: visible !important;
    }
    #calendar .fc-daygrid-event .fc-event-title,
    #calendar .has-event .fc-daygrid-more-link.fc-more-link{
      display: none;
    }
    #calendar .has-event:not(.event-min_stay_blocked) .fc-daygrid-day-events {
      top: 0;
    }
    #calendar .fc-h-event.type-inquiry,
    #calendar .fc-h-event.type-reservation {
      margin-top: 0;
      z-index: 3;
    }
    #calendar .fc-daygrid-event.fc-event-start {
      display: flex;
      margin-left: 15px;
    }
    #calendar .has-event.event-reservation:first-of-type .fc-event.type-reservation:not(.fc-event-end) .event-content .event-avatar {
      visibility: hidden;
    }
    #calendar .fc-daygrid-day.event-reservation:has(.fc-event-start, .fc-event-end) .fc-daygrid-day-number {
      visibility: hidden;
    }
    #calendar .has-event.event-reservation .fc-daygrid-day-top .fc-daygrid-day-number{
      color: white !important;
    }
    #calendar .has-event.event-reservation.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-top .fc-daygrid-day-number{
      color: white;
    }
    #calendar .has-event .fc-daygrid-day-top .fc-daygrid-day-number {
      pointer-events: none;
    }
    #calendar .has-event.event-ical,
    #calendar .has-event.event-min_stay_blocked{
      & .fc-daygrid-day-events {
        top: 50px;
      }
    }
    #calendar .fc-view-harness .fc-multimonth .fc-multimonth-title {
      font-size: 19px;
    }
    #calendar .fc-day .price {
      padding-left: 10px;
      padding-right: 10px;
    }
    #calendar .fc-view-harness .fc-daygrid-day .fc-daygrid-day-top{
      padding-top: 18px !important;
    }
    #calendar .ical-source-wrapper {
      margin-inline: auto;
    }
  }
  @media (max-width: 1030px) {
    #calendar .ical-source-wrapper {
      margin-top: 20px;
    }
  }
  @media (max-width: 960px) {
    #calendar .ical-source-wrapper {
      margin-top: 5px !important;
      padding-block: 0px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    #calendar .fc-day .price-adjusted-mobile{
      display: block !important;
      position: absolute;
      top: 10px;
      left: 5px;
      font-size: 11px;
      line-height: 11px;
    }
    #calendar .fc-view-harness .fc-day.has-event .price-adjusted-mobile{
      display: none !important;
    }
    #calendar .fc-daygrid .fc-day.has-event .price {
      display: none !important;
    }
    #calendar .has-event.event-ical,
    #calendar .has-event.event-min_stay_blocked{
      & .ical-source-wrapper{
        font-size: 14px;
      }
    }
    #calendar .fc-view-harness .fc-daygrid-day .fc-daygrid-day-top{
      padding: 8px;
      font-weight: 500;
      z-index: 999;
      justify-content: center;
      & .fc-daygrid-day-number {
        transition: all .2s !important;
      }
      & .fc-day-today-label {
        display: none;
      }
      & .minStay{
        position: absolute;
        top: 10px;
        right: 5px;
        & .minStayLabel {
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
    #calendar .fc-day .price {
      display: flex;
      justify-content: center !important;
      padding-top: 0 !important;
    }
    #calendar .fc-day .price .price-adjusted{
      display: none;
    }
    #calendar .fc-day .ical-source-wrapper {
      margin-top: 10px;
      margin-inline: auto !important;
    }
  }
  @media (max-width: 800px){
    #calendar .fc-view-harness .fc-daygrid-day .fc-daygrid-day-top{
      padding-top: 16px !important;
    }
    #calendar .has-event.event-ical,
    #calendar .has-event.event-min_stay_blocked{
      & .fc-daygrid-day-events {
        top: 45px;
      }
    }
    #calendar .fc-h-event.type-inquiry,
    #calendar .fc-h-event.type-reservation{
      padding: 5px !important;
    }
  }
  @media (max-width: 650px){
    #calendar .fc-view-harness .has-event.event-ical,
    #calendar .has-event.event-min_stay_blocked{
      & .ical-source-wrapper{
        display: none !important;
      }
    }
    #calendar .fc-daygrid-event.fc-event-start {
      margin-left: 10px;
    }
    #calendar .has-event .fc-event.fc-daygrid-event .event-content .event-avatar{
      min-width: 23px;
      min-height: 23px;
      max-width: 23px;
      max-height: 23px;
      font-size: 12px;

      & img {
        min-width: 23px;
        min-height: 23px;
        max-width: 23px;
        max-height: 23px;
      }
    }
    #calendar .fc-view-harness .fc-day .price {
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      margin: auto;
      font-weight: 400;
      font-size: 11px;
    }
    #calendar .fc-view-harness .fc-daygrid-day .fc-daygrid-day-top .minStay{
      position: absolute;
      top: 5px;
      right: 5px;
    }
    #calendar .fc-view-harness .fc-daygrid-day .price-adjusted-mobile{
      top: 5px;
    }
    #calendar .fc-daygrid-day-top .fc-daygrid-day-number{
      font-weight: 500;
      font-size: 12px !important;
    }
    #calendar .fc-view-harness .fc-daygrid-day .fc-daygrid-day-top .minStayDot{
      margin-right: 2px !important;
    }
    #calendar .fc-h-event.type-inquiry,
    #calendar .fc-h-event.type-reservation{
      padding: 5px !important;
    }
  }
  @media (max-width: 456px){
    #calendar .has-event .fc-event.fc-daygrid-event {
      margin-top: 5px;
    }
    #calendar .fc-daygrid-event.fc-event{
      padding: 4px;
    }
    #calendar .fc-view-harness .fc-daygrid-day .fc-daygrid-day-top{
      padding-top: 14px !important;
    }
    #calendar .fc-view-harness .fc-daygrid-day .price-adjusted-mobile{
      display: none !important;
    }
  }
  @media (max-width: 400px){
    #calendar .fc-daygrid-event.fc-event-start {
      margin-left: 5px !important;
    }
    #calendar .fc-h-event.type-inquiry,
    #calendar .fc-h-event.type-reservation{
      padding: 5px !important;
    }
    #calendar .fc-day .price{
      font-size: 10px !important;
      padding-top: 0px !important;
    }
    #calendar .fc-daygrid-day-top{
      padding: 4px !important;
      & .fc-daygrid-day-number{
        font-size: 12px !important;
        & .minStay{
          font-size: 10px !important;
        }
      }
    }
  }

  #calendar .fc-daygrid .fc-day.has-event.has-event.event-reservation,
  #calendar .fc-daygrid .fc-day.has-event.has-event.event-inquiry{
    .price {
      display: none;
    }
  }
  #calendar .fc-view-harness .fc-daygrid .fc-day .price-adjusted-mobile{
    display: none;
  }
  #calendar .fc-view-harness .fc-daygrid .fc-day.has-event.event-manual,
  #calendar .fc-view-harness .fc-daygrid .fc-day.has-event.event-ical,
  #calendar .fc-view-harness .fc-daygrid .fc-day.has-event.event-min_stay_blocked{
    & .price{
      display: none;
    }
  }
  #calendar .fc-daygrid .fc-day.has-event.event-manual .minStay,
  #calendar .fc-daygrid .fc-day.has-event.event-ical .minStay{
    display: none !important;
  }
  #calendar .fc-daygrid-day-top .fc-daygrid-day-number{
    font-weight: 500;
    font-size: 14px;
  }
  #calendar .fc-day .fc-daygrid-day-top .minStay{
    font-size: 14px !important;
  }
  // EVENTS END
  .hover-effect{
    border-top: 2px solid !important;
    border-bottom: 2px solid !important;
  }
  .hover-effect-start{
    border: 2px solid black !important;
    border-right: none !important;
  }
  .hover-effect-end{
    border: 2px solid black !important;
    border-left: none !important;
  }
  .fc .fc-daygrid-day-bg .fc-highlight{
    // background: #fffad7;
    // background: #fff3e7;
    background: rgba(161, 209, 241, 0.3);
  }
  .hover-day{
    background: rgb(243, 243, 243);
  }
  #calendar .fc-day .fc-daygrid-day-top {
    justify-content: space-between;
    align-items: center;
    flex-flow: inherit;
    padding: 15px;
    & .minStay{
      display: flex;
      align-items: center;
    }
    & .minStay .minStayDot{
      display: block;
      min-height: 6px;
      min-width: 6px;
      max-height: 6px;
      max-width: 6px;
      border-radius: 99999px;
      margin-right: 5px;
    }
  }

  .fc .fc-view-harness{
    margin-bottom: 50px;
  }
  #calendar .fc-multimonth{
    border: none;
  }
  #calendar .fc-view-harness tr:first-child .fc-daygrid-day-frame {
    border-top: 1px solid lightgray;
  }
  #calendar .fc-view-harness .fc-multimonth {
    border-bottom: none;
  }
  #calendar .fc-view-harness .fc-multimonth-month:last-child tr:last-child .fc-daygrid-day-frame {
    border-bottom: 1px solid lightgray;
  }
  #calendar .fc-header-toolbar, .fc-toolbar{
    display: none;
  }
  #calendar .fc-multimonth .fc-multimonth-header-table  {
    display: none;
  }
  #calendar .fc-multimonth .fc-multimonth-title {
    text-align: left;
    padding-left: 20px;
    font-size: 22px;
    font-weight: 500;
  }
  #calendar .fc-multimonth .fc-multimonth-month:not(:first-child){
    margin-top: 30px;
  }
  #calendar .fc-multimonth .fc-day-disabled {
    visibility: hidden;
    border: none;
  }
  .day-name-short div {
    display: block;
  }
  .fc-daygrid-event-harness {
    margin-top: 10px;
  }
  #calendar{
    .event-ical,
    .event-manual,
    .event-min_stay_blocked{
      .fc-daygrid-day-top{
        text-decoration: line-through;
      }
    }
  }
  #calendar.non-admin-styles .fc-view-harness .fc-daygrid-day.fc-day-past{
    background: rgb(226, 226, 218, 0.7);
    cursor: not-allowed;
    & .fc-daygrid-day-frame{
      & .price {
        opacity: 0.7;
      }
      & .fc-daygrid-day-top {
        & .fc-daygrid-day-number{
          cursor: not-allowed;
          color: rgb(98, 98, 98);
        }
      }
      & .ical-source-wrapper{
        background: rgb(247, 247, 244);
      }
    }
  }
  #calendar .fc-view-harness .fc-daygrid-day.fc-day-past.has-event.event-ical{
    & .fc-daygrid-day-number{
      background: rgb(247, 247, 244);
    }
  }
  #calendar .fc-view-harness .fc-multimonth-daygrid-table td.fc-daygrid-day.event-ical{
    cursor: not-allowed;
    & .fc-daygrid-day-number{
      cursor: not-allowed;
    }
  }
  #calendar .fc-view-harness .fc-daygrid-day.event-inquiry,
  #calendar .fc-view-harness .fc-daygrid-day.event-reservation{
    cursor: pointer !important;
  }
  #calendar .fc-view-harness .fc-daygrid-day.fc-day-today{
    background: none;
    .fc-daygrid-day-top .fc-daygrid-day-number{
      font-weight: 600;
      color: rgb(78, 78, 78);
    }
    .fc-daygrid-day-top .fc-day-today-label{
      margin-left: 8px;
    }
  }
  #calendar .fc-view-harness .fc-daygrid-day {
    cursor: pointer;
    -moz-user-select: none !important;
    -khtml-user-select: none !important;
    user-select: none !important;
    transition: all .2s;
  }
  .divider-thick{
    content: '';
    min-height: 3px;
    background: lightgray;
    min-width: 100%;
    display: block;
    margin: 16px 0;
  }
  .disable-date-cell-click #calendar .fc-view-harness .fc-daygrid-day.fc-day:not(.enable-date-cell-click ):not(.fc-day-past){
    cursor: not-allowed !important;
    background: rgb(247, 247, 244);
    & .fc-daygrid-day-frame {
      pointer-events: none !important;
    }
    & .ical-source-wrapper,
      .fc-daygrid-day-number{
        background: rgb(247, 247, 244);
      }
  }
  .disable-date-cell-click #calendar .fc-view-harness .fc-daygrid-day.enable-date-cell-click {
    cursor: pointer !important;
    & .fc-daygrid-day-frame {
      pointer-events: all !important;
    }
  }
  // fixes row height on mobile when month has 6 rows
  #calendar .fc-view-harness .fc-multimonth .fc-multimonth-daygrid-table:has(tr:first-child:nth-last-child(6)) {
    min-height: 340px !important;
  }
  #calendar .fc-view-harness .type-reservation.fc-daygrid-event.fc-h-event {
    min-width: 37px !important;
  }
  </style>
